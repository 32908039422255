import React from "react";
import styled from "styled-components";
import { graphql, useStaticQuery } from "gatsby";
import { getImage, StaticImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";
import Layout from "../components/layout";
import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import scrollTo from "gatsby-plugin-smoothscroll";
import SEO from "../components/seo";
const OurWorkSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;

  h2 {
    font-size: 40px;
    font-family: "Eames Century Modern", sans-serif;
    font-style: italic;
    line-height: 48px;
    vertical-align: middle;
  }
  p {
    font-family: "Brandon Text";
    font-size: 30px;
    line-height: 40.61px;
  }
  p:nth-child(3) {
    margin-top: 30px;
  }
  #header {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  span {
    font-family: "Brandon Text";
  }
  @media screen and (max-width: 906px) {
    p:nth-child(3) {
      margin-top: 10px;
    }
  }
`;
const CheatWrapper = styled.div`
  max-width: 1920px;
  @media screen and (max-width: 1700px) {
    max-width: 1700px;
  }
  @media screen and (max-width: 1500px) {
    max-width: 1500px;
  }
  @media screen and (max-width: 1291px) {
    max-width: 1291px;
  }
  @media screen and (max-width: 1111px) {
    max-width: 1111px;
  }
  @media screen and (max-width: 1019px) {
    max-width: 1019px;
  }
  @media screen and (max-width: 906px) {
    max-width: 906px;
  }
  @media screen and (max-width: 809px) {
    max-width: 809px;
  }
  @media screen and (max-width: 700px) {
    max-width: 700px;
  }
  @media screen and (max-width: 593px) {
    max-width: 593px;
  }
  @media screen and (max-width: 415px) {
    max-width: 415px;
  }
`;
const CoverPage = styled(BgImage)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 1080px;
  h1 {
    font-family: "Eames Century Modern", sans-serif;
    font-style: italic;
    font-size: 35px;
    line-height: 42px;
    font-weight: 400;
    max-width: 1042px;
  }

  .arrowSmall {
    display: none;
  }
  @media screen and (max-width: 1111px) {
    max-width: 1111px;

    #LOGO {
      width: 275px;
      height: auto !important;
      margin: auto;
    }
    h1 {
      width: 1000px;
      font-size: 28px;
    }
  }
  @media screen and (max-width: 1019px) {
    h1 {
      width: 800px;
    }
  }
  @media screen and (max-width: 817px) {
    h1 {
      width: 600px;
    }
  }
  @media screen and (max-width: 621px) {
    #LOGO {
      width: 200px;
    }
    h1 {
      font-size: 20px;
      line-height: 32px;
      width: 420px;
    }
    .arrowBig {
      display: none;
    }
    .arrowSmall {
      margin-top: 60px;
      width: 100%;
      display: block;
    }
  }
  @media screen and (max-width: 437px) {
    .arrowBig {
      display: none;
    }
    .arrowSmall {
      margin-top: 80px;
      width: 100%;
      display: block;
    }
    h1 {
      margin-top: 0;
      line-height: 24px;
      max-width: 333px;
    }
  }
`;
const HealthLiteracy = styled.div`
  display: flex;

  flex-direction: column;
  background: rgb(243, 117, 73);

  background: linear-gradient(
    180deg,
    #f69145 0%,
    #f47749 34.38%,
    #f37649 65.62%,
    #ef435a 100%
  );
  align-items: flex-start;
  padding-top: 80px;
  height: 1134px;
  padding-left: 100px;
  padding-right: 100px;
  em {
    cursor: pointer;
    text-decoration: underline;
    color: white;
  }
  span {
    font-size: 10px;
    line-height: 13.21px;
    max-width: 422px;
  }
  p:not(:nth-of-type(1)) {
    margin-bottom: 90px;
  }

  h1 {
    font-family: "Eames Century Modern", sans-serif;
    font-size: 70px;
    line-height: 83.65px;
    margin-bottom: 10px;
  }
  h5 {
    display: flex;
    flex-direction: row;
    line-height: 40.61px;
    margin-right: auto;
  }
  h5:nth-of-type(1) {
    font-size: 30px;
    margin-top: 75px;
    margin-bottom: 10px;
  }
  h5:not(:nth-of-type(1)) {
    font-family: "Eames Century Modern", sans-serif;
    font-style: italic;
    font-size: 40px;
    line-height: 48px;
    font-style: italic;
  }
  @media screen and (max-width: 1111px) {
    h1 {
      font-size: 65px;
    }
    p {
      font-size: 25px;
    }
    h5:nth-of-type(1) {
      font-size: 25px;
    }
    h5:not(:nth-of-type(1)) {
      font-size: 35px;
    }
  }
  @media screen and (max-width: 906px) {
    height: 1050px;
    h1 {
      font-size: 55px;
      line-height: 70px;
    }
    p {
      font-size: 20px;
    }
    p:not(:nth-of-type(1)) {
      margin-bottom: 75px;
    }

    span {
      font-size: 8px;
    }
    h5:nth-of-type(1) {
      font-size: 20px;
    }
    h5:not(:nth-of-type(1)) {
      font-size: 30px;
    }
  }
  @media screen and (max-width: 809px) {
    height: 950px;
    padding-top: 40px;
    h1 {
      font-size: 45px;
      line-height: 65px;
    }
    p {
      font-size: 15px;
    }
    h5:nth-of-type(1) {
      font-size: 15px;
    }
    h5:not(:nth-of-type(1)) {
      font-size: 20px;
    }
  }
  @media screen and (max-width: 700px) {
    height: 975px;
    h1 {
      font-size: 40px;
      line-height: 60px;
    }
  }
  @media screen and (max-width: 622px) {
    padding-left: 25px;
    padding-right: 25px;
    h1 {
      line-height: 50px;
      font-size: 30px;
    }
  }
  @media screen and (max-width: 501px) {
    height: 800px;
    padding-left: 25px;
    padding-top: 40px;
    padding-right: 25px;
    h1 {
      font-size: 35px;
      line-height: 41.83px;
      max-width: 333px;
    }
    p {
      font-size: 18px;
      line-height: 24.36px;
    }
    p:not(:nth-of-type(1)) {
      margin-bottom: 50px;
    }
    span {
      max-width: 309px;
    }
    h5:nth-of-type(1) {
      margin-top: 50px;
      font-size: 18px;
      margin-bottom: 0;
    }
    h5:not(:nth-of-type(1)) {
      margin-bottom: -16px;
      font-size: 20px;
      display: flex;
      align-items: center;
    }
    #logo {
      height: auto !important;
      width: 25px;
      margin-top: auto;
      margin-bottom: auto;
    }
  }
  @media screen and (max-width: 410px) {
    height: 900px;
  }
`;

const Families = styled.div`
  display: flex;
  flex-direction: column;
  background: rgb(239, 67, 90);
  background: linear-gradient(
    180deg,
    #ef435a 0%,
    #f04958 34.9%,
    #f05051 61.98%,
    #f37549 100%
  );
  height: 758px;
  padding-top: 80px;
  padding-left: 100px;
  padding-right: 100px;
  h1 {
    font-family: "Eames Century Modern", sans-serif;
    font-size: 70px;
    line-height: 83.65px;
    margin-bottom: 10px;
  }
  @media screen and (max-width: 1111px) {
    h1 {
      font-size: 65px;
    }
    p {
      font-size: 25px;
    }
  }
  @media screen and (max-width: 920px) {
    height: 800px;
    h1 {
      font-size: 45px;
      line-height: 70px;
    }
    p {
      font-size: 20px;
    }
  }
  @media screen and (max-width: 809px) {
    height: 600px;
    padding-top: 40px;
    h1 {
      font-size: 45px;
      line-height: 65px;
    }
    h2 {
      font-size: 30px;
    }
    p {
      font-size: 15px;
    }
  }
  @media screen and (max-width: 720px) {
    height: 675px;
    h1 {
      font-size: 35px;
      line-height: 60px;
    }
    h2 {
      font-size: 25px;
    }
  }
  @media screen and (max-width: 622px) {
    height: 625px;
    padding-left: 25px;
    padding-right: 25px;
    #header {
      display: flex;
      flex-direction: column;
      align-items: start;
    }
    h1 {
      margin-bottom: 0;
      font-size: 35px;
      line-height: 41.83px;
    }
    h2 {
      font-size: 20px;
    }
  }
  @media screen and (max-width: 501px) {
    height: 650px;
    padding-left: 25px;
    padding-top: 40px;
    padding-right: 25px;
    #header {
      align-items: start;
      display: flex;
      flex-direction: column;
    }
    p {
      font-size: 18px;
      line-height: 24.36px;
    }
  }
`;

const FPTH = styled(BgImage)`
  display: flex;
  flex-direction: row;
  padding-top: 80px;
  height: 1080px;
  padding-left: 100px;
  align-items: center;
  justify-content: space-between;

  .containerPHA {
    display: grid;
    margin-top: -80px;
    margin-left: -100px;
  }
  .containerMD {
    display: grid;
    margin-top: -80px;
    margin-left: -100px;
  }

  .right {
    padding-right: 50px;
    height: 100%;
    padding-left: 100px;
    margin-top: 200px;
    grid-column: 2 / span 1;
    grid-row: 1 / span 2;
  }

  .left {
    padding-top: 60px;
    width: 550px;
    padding-left: 80px;
  }

  h1 {
    font-family: "Eames Century Modern", sans-serif;
    font-size: 100px;
    margin-left: 50px;
    margin-top: 28px;
    line-height: 120px;
  }
  h1 span {
    font-family: "Eames Century Modern", sans-serif;
    font-weight: 400;
    font-style: italic;
  }
  h3 {
    font-family: "Brandon Grotesque";
    font-weight: 800;
    text-align: center;
    font-size: 32px;
    line-height: 42.9px;
    margin-bottom: 36px;
    margin-right: 100px;
  }
  p {
    font-family: "Brandon Text";
    width: 800px;
    font-size: 32px;
    line-height: 40.61px;
  }
  @media screen and (max-width: 1700px) {
    height: 900px;
    .right {
      padding-right: 0;
      padding-left: 0;
    }
    h3 {
      font-size: 32px;
    }
    p {
      font-size: 32px;
      max-width: 550px;
    }
  }
  @media screen and (max-width: 1500px) {
    .right {
      padding-left: 80px;
    }
    h1 {
      font-size: 80px;
      line-height: 100px;
    }
    p {
      font-size: 24px;
      max-width: 492px;
    }
    h3 {
      font-size: 25px;
      margin-right: 0;
    }
  }

  @media screen and (max-width: 1291px) {
    height: 758px;
    .right {
      padding-right: 0;
      padding-left: 0;
    }
    h1 {
      font-size: 70px;
      line-height: 85px;
    }
    p {
      font-size: 20px;
      max-width: 492px;
    }
    h3 {
      margin-right: 0;
    }
    img {
      width: 130px;
    }
  }
  @media screen and (max-width: 1208px) {
    .right {
      margin-top: 175px;
      margin-left: -50px;
    }
    p {
      max-width: 380px;
    }
  }
  @media screen and (max-width: 1059px) {
    height: 700px;
    .right {
      margin-top: 150px;
      margin-left: -50px;
    }
    p {
      max-width: 380px;
    }
    h1 {
      width: 300px;
      margin-left: 25px;
      font-size: 60px;
      line-height: 75px;
    }
  }
  @media screen and (max-width: 950px) {
    .right {
      margin-left: 50px;
    }
    .left {
      padding-top: 80px;
      padding-left: 70px;
    }
    p {
      font-size: 20px;
    }
    h1 {
      margin-left: 0;
      font-size: 55px;
      line-height: 70px;
    }
  }
  @media screen and (max-width: 850px) {
    .right {
      margin-left: 25px;
    }

    p {
      font-size: 17px;
    }
  }
  @media screen and (max-width: 825px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    .right {
      margin: 0;
    }
    h3 {
      font-size: 35px;
    }

    p {
      font-size: 20px;
      max-width: 500px;
    }
  }
  @media screen and (max-width: 630px) {
    h3 {
      font-size: 30px;
    }
    p {
      font-size: 17px;
    }
  }

  @media screen and (max-width: 524px) {
    padding-left: 100px;
    padding-top: 0;
    padding-right: 0;

    h1 {
      font-size: 20px;
      margin-left: 0;
      margin-top: 0;
      line-height: 24px;
    }
    h3 {
      font-size: 15px;
      margin-right: 0;
    }
    p {
      font-size: 18px;
      line-height: 24.36px;
      width: 337px;
    }
    //.containerPHA {
    //  grid-column: 1 / span 2;
    //  grid-row: 1 / span 1;
    //}
    // .left {
    //   padding-top: 0;
    //  width: 300px;
    // }
    .right {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-right: 0px;
      height: 100%;
      padding-left: 0px;
      margin-top: 0px;
      // grid-column: 1 / span 2;
      // grid-row: 2 / span 2;
    }
  }
`;
const Vector = styled(BgImage)`
  height: 1080px;
  width: 900px;
  @media screen and (max-width: 1700px) {
    height: 900px;
  }
  @media screen and (max-width: 1500px) {
    height: 900px;
    width: 700px;
  }
  @media screen and (max-width: 1291px) {
    height: 758px;
  }
  @media screen and (max-width: 1059px) {
    width: 600px;
    height: 700px;
  }
  @media screen and (max-width: 950px) {
    width: 400px;
  }
  @media screen and (max-width: 825px) {
    display: none;
  }
  @media screen and (max-width: 415px) {
    //height: 500px;
    //width: 100%;
    display: none;
  }
`;
const Advoc = styled.div`
  display: flex;
  flex-direction: column;
  background: #fbf7f3;
  height: 900px;
  align-items: center;
  padding-top: 300px;
  h1 {
    font-family: "Eames Century Modern", sans-serif;
    font-weight: 700;
    font-size: 40px;
    margin-bottom: 60px;
    color: #f37549;
    max-width: 1059px;
  }
  .illus {
    display: grid;
    grid-template-columns: 200px 200px 200px;
    grid-template-rows: auto;
    grid-gap: 250px;
    align-self: center;
  }
  span {
    text-align: center;
  }
  h3 {
    font-family: "Brandon Grotesque";
    font-weight: 800;
    font-size: 30px;
    color: #ef4459;
    margin-top: 25px;
  }
  @media screen and (max-width: 1118px) {
    h1 {
      font-size: 35px;
    }
    h3 {
      font-size: 25px;
    }
    .illus {
      grid-gap: 200px;
    }
  }
  @media screen and (max-width: 1019px) {
    h1 {
      font-size: 33px;
    }
    .illus {
      grid-gap: 150px;
    }
  }
  @media screen and (max-width: 920px) {
    h1 {
      font-size: 30px;
    }
    img {
      height: 100px;
      width: 100%;
    }
    h3 {
      font-size: 20px;
    }
    .illus {
      grid-gap: 100px;
    }
  }
  @media screen and (max-width: 817px) {
    height: 650px;
    padding-top: 200px;
    h1 {
      font-size: 25px;
    }
    .illus {
      grid-gap: 40px;
    }
  }
  @media screen and (max-width: 700px) {
    padding-top: 150px;
    height: 1080px;
    h1 {
      font-size: 20px;
    }
    .illus {
      grid-template-rows: 200px 200px 200px;
      grid-template-columns: auto;
      grid-gap: 40px;
    }
    h3 {
      font-size: 20px;
    }
    img {
      width: 100%;
      height: 100px;
    }
  }
  @media screen and (max-width: 593px) {
    h1 {
      font-size: 25px;
      text-align: center;
      width: 350px;
    }
  }
  @media screen and (max-width: 440px) {
    padding-top: 80px;
    height: 1080px;
    h1 {
      text-align: center;
      font-size: 20px;
      line-height: 23.9px;
      width: 300px;
    }
    .illus {
      grid-template-rows: 200px 200px 200px;
      grid-template-columns: auto;
      grid-gap: 80px;
    }
    h3 {
      font-size: 20px;
      line-height: 28.6px;
      width: 155px;
    }
    img {
      width: 100%;
      height: 100px;
    }
  }
`;
function OurWork() {
  gsap.registerPlugin(ScrollToPlugin);
  const {
    OurWorkImage,
    OurWorkPHAImage,
    OurWorkMDImage,
    OurWorkVectorImage,
    SEOImg,
  } = useStaticQuery(graphql`
    query OurWorkHeroBG {
      OurWorkImage: file(
        relativePath: { eq: "OurWork/OurWorkBackground.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 1080
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
            quality: 100
          )
        }
      }
      OurWorkPHAImage: file(relativePath: { eq: "OurWork/OurWorkPHA.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
            quality: 100
          )
        }
      }
      OurWorkMDImage: file(relativePath: { eq: "OurWork/OurWorkMD.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
            quality: 100
          )
        }
      }
      OurWorkVectorImage: file(
        relativePath: { eq: "OurWork/OurWorkVector1.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            width: 750
            formats: [AUTO, WEBP, AVIF]
            quality: 100
          )
        }
      }
      SEOImg: file(relativePath: { eq: "hero.jpg" }) {
        childImageSharp {
          fluid(quality: 95, maxWidth: 1200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  const OurWorkBG = getImage(OurWorkImage);
  const OurWorkPHABG = getImage(OurWorkPHAImage);
  const OurWorkMDBG = getImage(OurWorkMDImage);
  const OurWorkVector = getImage(OurWorkVectorImage);
  const backgroundFluidImageStack = [
    `linear-gradient(rgba(0,0,0, 0.5), rgba(0, 0, 0, 0.5))`,
    OurWorkBG,
  ];
  const backgroundPHABGStack = [
    `linear-gradient(rgba(0,0,0, 0.5), rgba(0, 0, 0, 0.5))`,
    OurWorkPHABG,
  ];
  const backgroundMDStack = [
    `linear-gradient(rgba(0,0,0, 0.5), rgba(0, 0, 0, 0.5))`,
    OurWorkMDBG,
  ];

  return (
    <Layout identifier="Our Work">
      <SEO title="Our Work" image={SEOImg.childImageSharp.fluid} />
      <OurWorkSection>
        <CheatWrapper>
          <CoverPage image={backgroundFluidImageStack}>
            <StaticImage
              src="../images/OurWork/OurWorkLogoLAAN.png"
              placeholder="blurred"
              width={303.75}
              alt=""
              id="LOGO"
            ></StaticImage>

            <h1 style={{ marginBottom: "0" }}>
              We empower people to stand up for their right to health. For ten
              years since 2011, Project LAAN has been empowering communities to
              assert their rights, and make informed health decisions.
            </h1>

            <svg
              className="arrowBig"
              style={{ marginTop: "52px" }}
              width="30"
              height="88"
              viewBox="0 0 30 88"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.5858 87.4142C14.3668 88.1953 15.6332 88.1953 16.4142 87.4142L29.1421 74.6863C29.9232 73.9052 29.9232 72.6389 29.1421 71.8579C28.3611 71.0768 27.0948 71.0768 26.3137 71.8579L15 83.1716L3.68629 71.8579C2.90524 71.0768 1.63891 71.0768 0.857864 71.8579C0.0768156 72.6389 0.0768156 73.9052 0.857864 74.6863L13.5858 87.4142ZM13 0L13 86H17L17 0L13 0Z"
                fill="white"
              />
            </svg>

            <svg
              className="arrowSmall"
              width="16"
              height="55"
              viewBox="0 0 16 55"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.29289 54.7071C7.68342 55.0976 8.31658 55.0976 8.70711 54.7071L15.0711 48.3432C15.4616 47.9526 15.4616 47.3195 15.0711 46.9289C14.6805 46.5384 14.0474 46.5384 13.6569 46.9289L8 52.5858L2.34315 46.9289C1.95262 46.5384 1.31946 46.5384 0.928932 46.9289C0.538408 47.3195 0.538408 47.9526 0.928932 48.3432L7.29289 54.7071ZM7 0L7 54H9L9 0L7 0Z"
                fill="white"
              />
            </svg>
          </CoverPage>
          <HealthLiteracy>
            <h1>
              HEALTH <br />
              LITERACY
            </h1>
            <p>
              One of the most powerful tools in combating social inequities is
              health literacy, where people who are health literate can access,
              understand, and evaluate information to make better decisions for
              their health, family, and community. Moreover, they can navigate
              through health systems with ease, especially when fair treatment
              is absent.¹
            </p>
            <p>
              We educate our families to improve health literacy, which is
              essential for them to overcome the many barriers they face to the
              health services they need. Ultimately, this is our way to advance
              the vision of Universal Health Coverage – health for all people.
            </p>
            <StaticImage
              src="../images/OurWork/OurWorkLine.svg"
              placeholder="blurred"
              style={{ marginBottom: "8px" }}
              alt="Line"
              id="line"
            ></StaticImage>
            <span>
              Dodson S, Good S, Osborne RH. Health literacy Toolkit for Low- and
              Middle-Income Countries: a Series of Information Sheets to Empower
              Communities and Strengthen Health Systems. New Delhi: World Health
              Organization, Regional Office for South-East Asia, 2015.
            </span>

            <h5>Read more about our, </h5>
            <h5>
              <em onClick={() => scrollTo("#OurWorkStart")}>
                Public Health Advocacy
              </em>{" "}
              <StaticImage
                src="../images/OurWork/OurWorkLogoLAAN.png"
                placeholder="blurred"
                width={50.62}
                style={{ marginLeft: "6px" }}
                alt="Logo"
                id="logo"
              ></StaticImage>
            </h5>

            <h5>
              <em onClick={() => scrollTo("#OurWorkEnd")}>
                Member Development
              </em>{" "}
              <StaticImage
                src="../images/OurWork/OurWorkLogoLAAN.png"
                placeholder="blurred"
                width={50.62}
                style={{ marginLeft: "6px" }}
                id="logo"
                alt="Logo"
              ></StaticImage>
            </h5>
          </HealthLiteracy>

          <Families>
            <div id="header">
              <h1>FAMILIES -&nbsp;</h1>
              <h2>Calawis, Antipolo</h2>
            </div>

            <p>
              Imagine descending a mountain on steep dirt roads, for four hours,
              just to get to the nearest health facility, and possibly being
              denied of services. Now consider doing so while you are sick. For
              our families in Calawis, it is not difficult to imagine, because
              they have to go through this exact ordeal every time they face
              serious health concerns. Since 2013, we have been giving health
              education to over a hundred families in Calawis. More than
              fighting for their right to health, we have been able to get to
              know them more intimately. At present, we go on monthly visits to
              the mountains, and constantly look forward to seeing our ates,
              kuyas, lolos, and lolas, who all share the genuine desire to
              improve the health of their community.
            </p>
          </Families>

          <FPTH image={backgroundPHABGStack} id="OurWorkStart">
            <div id="PHA" className="containerPHA">
              <Vector image={OurWorkVector}>
                <div className="left">
                  <StaticImage
                    src="../images/OurWork/OurWorkLogoLAAN.png"
                    placeholder="blurred"
                    width={166}
                    alt="Logo"
                  ></StaticImage>

                  <h1>
                    Fighting Poverty <span>Through</span> Health
                  </h1>
                </div>
              </Vector>
              <div className="right">
                <h3>PUBLIC HEALTH ADVOCACY</h3>
                <p>
                  Every year, Project LAAN holds its Universal Health Coverage
                  (UHC) Summit and several Public Health Forums that center on
                  how health-for-all can be achieved in the Philippines, and how
                  the health sector and the youth can contribute to
                  nation-building.
                </p>
                <p>
                  Moreover, we regularly organize campaigns to promote core
                  messages in health advocacy towards stronger awareness and
                  concrete action.
                </p>
              </div>
            </div>
          </FPTH>
          <FPTH image={backgroundMDStack} id="OurWorkEnd">
            <div id="MD" className="containerMD">
              <Vector image={OurWorkVector}>
                <div className="left">
                  <StaticImage
                    src="../images/OurWork/OurWorkLogoLAAN.png"
                    placeholder="blurred"
                    width={166}
                    alt="Logo"
                  ></StaticImage>

                  <h1>
                    Fighting Poverty <span>Through</span> Health
                  </h1>
                </div>
              </Vector>
              <div className="right">
                <h3>MEMBER DEVELOPMENT</h3>
                <p>
                  Project LAAN is more than a youth-led organization, it is
                  family. We build on real-world skills through project
                  management, training sessions, and various opportunities for
                  member involvement. But we also build relationships through
                  various bonding activities and a cluster mentoring and care
                  system. Our goal is to develop public health advocates in all
                  our members, regardless of their field or discipline.
                </p>
              </div>
            </div>
          </FPTH>
          <Advoc>
            <h1>
              Advocating for the right to health of all Filipinos through,
            </h1>
            <div className="illus">
              <span>
                <StaticImage
                  src="../images/OurWork/OurWorkAdvoc1.svg"
                  placeholder="blurred"
                  width={159}
                  alt="illustration"
                  quality={100}
                ></StaticImage>

                <h3>SERVICE</h3>
              </span>
              <span>
                <StaticImage
                  src="../images/OurWork/OurWorkAdvoc2.svg"
                  placeholder="blurred"
                  width={151}
                  alt="illustration"
                  quality={100}
                ></StaticImage>

                <h3>EMPOWERMENT</h3>
              </span>
              <span>
                <StaticImage
                  src="../images/OurWork/OurWorkAdvoc3.svg"
                  placeholder="blurred"
                  width={115}
                  alt="illustration"
                  quality={100}
                ></StaticImage>

                <h3>CREATIVITY & INNOVATION</h3>
              </span>
            </div>
          </Advoc>
        </CheatWrapper>
      </OurWorkSection>
    </Layout>
  );
}

export default OurWork;
